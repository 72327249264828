@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Lato Regular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato Bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Lato Black';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/Lato/Lato-Black.ttf') format('truetype');
  }

  body {
    @apply antialiased;
    background-color: #fff;
  }
}

.recurly-element-number,
.recurly-element-month,
.recurly-element-year,
.recurly-element-cvv {
  width: 100%;
  border: 0;
  margin-top: 0;
}
